import { useEffect } from "react";
import useRainforestScript, { RainforestScriptType } from "./hooks/useRainforestScript";

export interface RainforestApproval {
    data: {
        payin_id: string;
        payin_config_id: string;
        merchant_id: string;
        payment_method_id: string;
        payment_method_config_id: string;
        amount: number;
        currency_code: string;
        status: string;
        idempotency_key: string;
        method_type: string;
        card: {
            type: string;
            brand: string;
            brand_desc: string;
            last_4: string;
            exp_month: number;
            exp_year: number;
        };
        billing_contact: {
            name: string;
            address_line_1?: string;
            address_line_2?: string;
            city?: string;
            state?: string;
            postal_code?: string;
            country?: string;
            email?: string;
            phone?: string;
        };
        metadata: {
            invoice_id?: string;
            payer_name?: string;
            payer_email?: string;
            payer_phone?: string;
        };
        created_at: string;
        updated_at: string;
    };
}

export interface RainforestDecline {
    data: {
        payin_id: string;
        refusal_code: string;
        refusal_desc: string;
    };
}

export interface RainforestError {
    code: string;
    message: string;
}

export interface RainforestAttempted {}

export interface RainforestValid {}

export interface RainforestInvalid {}

export interface RainforestConfigUpdateIgnored {}

export interface RainforestCardBrandUpdated {}

export interface Props {
    sessionKey: string;
    payinConfigId: string;
    hideButton?: boolean;
    onLoad?: (loaded: boolean) => void;
    onPaymentApproved?: (e: CustomEvent<RainforestApproval[]>) => void;
    onPaymentDeclined?: (e: CustomEvent<RainforestDecline[]>) => void;
    onPaymentError?: (e: CustomEvent<RainforestError[]>) => void;
    onPaymentAttempted?: (e: CustomEvent<RainforestAttempted[]>) => void;
    onPaymentValid?: (e: CustomEvent<RainforestValid[]>) => void;
    onPaymentInvalid?: (e: CustomEvent<RainforestInvalid[]>) => void;
    onConfigUpdateIgnored?: (e: CustomEvent<RainforestConfigUpdateIgnored[]>) => void;
    onCardBrandUpdated?: (e: CustomEvent<RainforestCardBrandUpdated[]>) => void;
}

const RainforestPayment: React.FC<Props> = ({
    sessionKey,
    payinConfigId,
    hideButton,
    onLoad,
    onPaymentApproved,
    onPaymentDeclined,
    onPaymentError,
    onPaymentAttempted,
    onPaymentValid,
    onPaymentInvalid,
    onConfigUpdateIgnored,
    onCardBrandUpdated,
}) => {
    useRainforestScript(RainforestScriptType.Payment, onLoad);

    useEffect(() => {
        const form = document.querySelector("rainforest-payment");

        form?.addEventListener("approved", onPaymentApproved as EventListener);
        form?.addEventListener("declined", onPaymentDeclined as EventListener);
        form?.addEventListener("error", onPaymentError as EventListener);
        form?.addEventListener("attempted", onPaymentAttempted as EventListener);
        form?.addEventListener("valid", onPaymentValid as EventListener);
        form?.addEventListener("invalid", onPaymentInvalid as EventListener);
        form?.addEventListener("config-update-ignored", onConfigUpdateIgnored as EventListener);
        form?.addEventListener("card-brand-updated", onCardBrandUpdated as EventListener);

        return () => {
            form?.removeEventListener("approved", onPaymentApproved as EventListener);
            form?.removeEventListener("declined", onPaymentDeclined as EventListener);
            form?.removeEventListener("error", onPaymentError as EventListener);
            form?.removeEventListener("attempted", onPaymentAttempted as EventListener);
            form?.removeEventListener("valid", onPaymentValid as EventListener);
            form?.removeEventListener("invalid", onPaymentInvalid as EventListener);
        };
    }, [
        // form props
        sessionKey,
        payinConfigId,
        hideButton,
        // event listener callbacks
        onPaymentApproved,
        onPaymentDeclined,
        onPaymentError,
        onPaymentAttempted,
        onPaymentValid,
        onPaymentInvalid,
        onConfigUpdateIgnored,
        onCardBrandUpdated,
    ]);

    // @ts-ignore
    return (
        <rainforest-payment
            data-testid="rainforest-payment"
            session-key={sessionKey}
            payin-config-id={payinConfigId}
            api-version="2023-12-01"
            allowed-methods="CARD"
            style-button-color="#212121"
            style-button-border-radius="90rem"
            style-font-family="'ProximaNova',sans-serif"
            {...(hideButton ? { "hide-button": true } : {})}
        />
    );
};

export default RainforestPayment;
