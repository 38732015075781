export class ChargebacksEndpoints {
    static getSessioKey = (): string => `/api/v1/chargebacks/session`;
    static getCompanyMerchantId = (companyId: string): string => `/api/v1/companies/${companyId}/get-rainforest-merchant`;
    static simuateChargeback = (): string => `/api/v1/rainforest/chargebacks/simulate`;
    static changeChargebackStatus = (chargebackId: string): string => `/api/v1/rainforest/chargeback/${chargebackId}/simulate`;
    static getScopedSessionKey = (companyId: string): string => `/api/v1/companies/${companyId}/chargebacks/session`;
    static getChargebackDetails = (chargebackId: string): string => `/api/v1/rainforest/chargeback/${chargebackId}`;
    static getMessages = (chargebackId: string): string => `/api/v1/rainforest/chargeback/${chargebackId}/messages`;
    static postMessage = (chargebackId: string): string => `/api/v1/rainforest/chargeback/${chargebackId}/messages`;
}
