export enum ChargebackType {
    INQUIRY_ACTION_REQUIRED = "INQUIRY_ACTION_REQUIRED",
    DISPUTE_ACTION_REQUIRED = "DISPUTE_ACTION_REQUIRED",
    INQUIRY_PROCESSING = "INQUIRY_PROCESSING",
    CHARGEBACK_PROCESSING = "CHARGEBACK_PROCESSING",
    PRE_ARBITRATION_PROCESSING = "PRE_ARBITRATION_PROCESSING",
    ARBITRATION = "ARBITRATION",
    PROVISIONAL_WIN = "PROVISIONAL_WIN",
    WON = "WON",
    LOST = "LOST",
}

export enum ChargebackReasonCode {
    PRODUCT_UNACCEPTABLE = "PRODUCT_UNACCEPTABLE",
    CREDIT_NOT_PROCESSED = "CREDIT_NOT_PROCESSED",
    DUPLICATE = "DUPLICATE",
    FRAUDULENT = "FRAUDULENT",
    GENERAL = "GENERAL",
    PRODUCT_NOT_RECEIVED = "PRODUCT_NOT_RECEIVED",
    SUBSCRIPTION_CANCELED = "SUBSCRIPTION_CANCELED",
    UNRECOGNIZED = "UNRECOGNIZED",
}

export enum ChargebackEvidenceType {
    _3DS_VERIFICATION = "3DS_VERIFICATION",
    ADDITIONAL_AUTHORIZED_SIGNER = "ADDITIONAL_AUTHORIZED_SIGNER",
    ADDITIONAL_HOUSEHOLD_SIGNER = "ADDITIONAL_HOUSEHOLD_SIGNER",
    AUTHORIZATION = "AUTHORIZATION",
    CANCELLATION_POLICY = "CANCELLATION_POLICY",
    CVV2_CAPTURE = "CVV2_CAPTURE",
    DIGITAL_DOWNLOAD = "DIGITAL_DOWNLOAD",
    ORDER_FORM = "ORDER_FORM",
    OTHER = "OTHER",
    OTHER_PAYMENT_METHODS = "OTHER_PAYMENT_METHODS",
    PRODUCT_OR_SERVICE_USAGE = "PRODUCT_OR_SERVICE_USAGE",
    PROOF_OF_DELIVERY_TO_EMPLOYEE = "PROOF_OF_DELIVERY_TO_EMPLOYEE",
    PROOF_OF_DELIVERY = "PROOF_OF_DELIVERY",
    REBUTTAL = "REBUTTAL",
    RECEIPT = "RECEIPT",
    RECURRING_BILLING_AGREEMENT = "RECURRING_BILLING_AGREEMENT",
    RENTAL_AGREEMENT = "RENTAL_AGREEMENT",
    TRANSACTION_HISTORY = "TRANSACTION_HISTORY",
}

export enum ChargebackMode {
    ALLOWED = "ALLOWED",
    NOT_ALLOWED = "NOT_ALLOWED",
    REQUIRED = "REQUIRED",
}

export interface Chargeback {
    chargeback_id: string;
    payin_id: string;
    merchant_id: string;
    amount: number; // in minor units
    currency_code: string; // ISO 4217, e.g., "USD"
    status: ChargebackType;
    metadata: Record<string, any>;
    merchant_fees: {
        method_type: "CARD" | "APPLE_PAY";
        card?: Record<string, any>;
        apple_pay?: Record<string, any>;
    };
    reason_code: ChargebackReasonCode;
    reason_desc: string;
    acquirer_ref: string;
    due_date: string; // ISO date (YYYY-MM-DD)
    chargeback_evidence: ChargebackEvidence[];
    expected_deposit_date: string; // ISO date (YYYY-MM-DD)
    created_at: string; // ISO 8601 date-time
    updated_at: string; // ISO 8601 date-time
    errors?: ValidationError[];
}

export interface ChargebackEvidence {
    chargeback_evidence_id: string;
    chargeback_id: string;
    is_required: boolean;
    type: ChargebackEvidenceType;
    type_desc: string;
    file_upload_mode: ChargebackMode;
    file_upload_ids: string[] | null;
    response_mode: ChargebackMode;
    response: string | null;
    created_at: string; // ISO 8601 date-time
    updated_at: string; // ISO 8601 date-time
    validation_errors?: ValidationError[];
}

export interface ValidationError {
    field: string;
    code: string;
    message: string;
}

export interface ChargebackStatusBody {
    status: ChargebackType;
}

export interface SimulateChargebackBody extends ChargebackStatusBody {
    payinId: string;
}

export interface RainforestMerchantInfo {
    merchant_id: string;
}

export interface ChargebackMeta {
    invoice_id: string | number;
    payer_emai: string;
    payer_name: string;
    payer_phone: string | number;
}

export interface ChargebackRow {
    amount: number; // in minor units
    id: string;
    status: ChargebackType;
    parent_id: string;
    metadata: ChargebackMeta;
    reason_code: ChargebackReasonCode;
}

export interface ChargebackEvent {
    created_at: string; // ISO date string
    status: ChargebackType;
    due_date: string; // ISO date string
}

export interface ChargebackDetails {
    created_at: string; // ISO date string
    updated_at: string; // ISO date string
    chargeback_id: string;
    status: string;
    due_date: string; // ISO date string
    payin_id: string;
    reason_code: ChargebackReasonCode;
    reason_desc: string;
    events: ChargebackEvent[];
}
export interface ChargebackMessage {
    id: string;
    createdAt: string;
    userId: string;
    userName: string;
    companyName: string;
    message: string;
}
