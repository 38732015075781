import { createSelector } from "reselect";
import { getCombinedUserRoleBooleans } from "./userSelectors";

export const canViewChargebacksPage = createSelector([getCombinedUserRoleBooleans], (roles) => {
    const { isCompanyAdmin, isRSImplementation, isSuperAdmin, isRSEmployee, isChargebackOps } = roles;
    return isSuperAdmin || isCompanyAdmin || isRSEmployee || isRSImplementation || isChargebackOps;
});

export const canEditChargebacksPage = createSelector([getCombinedUserRoleBooleans], (roles) => {
    const { isCompanyAdmin, isRSImplementation, isSuperAdmin, isRSEmployee, isChargebackOps } = roles;

    if (!isChargebackOps) {
        return false;
    }
    if (isSuperAdmin || isRSEmployee || isRSImplementation) {
        return true;
    }
    if (isCompanyAdmin) {
        return true;
    }
    return false;
});

export const isRSChargebacksOps = createSelector([getCombinedUserRoleBooleans], (roles) => {
    const { isRSImplementation, isSuperAdmin, isRSEmployee, isChargebackOps } = roles;
    if (!isChargebackOps) {
        return false;
    }
    if (isSuperAdmin || isRSEmployee || isRSImplementation) {
        return true;
    }
    return false;
});

export const isChargebackOps = createSelector([getCombinedUserRoleBooleans], (roles) => {
    const { isChargebackOps } = roles;
    return isChargebackOps;
});
