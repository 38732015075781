import { Roles } from "../../constants/roles";
import { AdminPaths, CheckPaths, WorkOrderPaths, InvoicePaths, DashboardPaths } from "./paths";
import { isCompanyTypeTowAndRepare, isDashboardEnabled, isInvoiceDisabled, isSecuredBankingEnabled, isWorkOrderEnabled } from "./company";
import { Company } from "../../types/Company";
import { User } from "../../types/User";

type UserRoleObject = Pick<User, "roles"> | User | undefined | any;

export function isSuperAdmin(me?: UserRoleObject): boolean {
    // SA
    return !!me?.roles?.find((item) => item === Roles.SUPER_ADMIN.key);
}

export function isCompanyAdmin(me?: UserRoleObject): boolean {
    // CA
    return !!me?.roles?.find((item) => item === Roles.COMPANY_ADMIN.key);
}

export function isReporter(me?: UserRoleObject): boolean {
    // CA
    return !!me?.roles?.find((item) => item === Roles.REPORTING.key);
}

export function isAccountant(me?: UserRoleObject): boolean {
    // AC
    return !!me?.roles?.find((item) => item === Roles.ACCOUNTANT.key);
}

export function isEmployee(me?: UserRoleObject): boolean {
    // EM
    return !!me?.roles?.find((item) => item === Roles.EMPLOYEE.key);
}

export function isClientSupport(me?: UserRoleObject): boolean {
    // CS
    return !!me?.roles?.find((item) => item === Roles.CLIENT_SUPPORT.key);
}

export function isRSEmployee(me?: UserRoleObject): boolean {
    // RSE
    return !!me?.roles?.find((item) => item === Roles.RSEMPLOYEE.key);
}

export function isRSImplementation(me?: UserRoleObject): boolean {
    // RSI
    return !!me?.roles?.find((item) => item === Roles.RS_IMPLEMENTATION.key);
}

export function isRSCheckOps(me?: UserRoleObject): boolean {
    return !!me?.roles?.find((item) => item === Roles.RS_CHECK_OPS.key);
}

export function isChargebackops(me?: UserRoleObject): boolean {
    return !!me?.roles?.find((item) => item === Roles.CHARGEBACK_OPS.key);
}

export function isFinancialAdmin(me?: UserRoleObject): boolean {
    return !!me?.roles?.find((item) => item === Roles.FINANCIAL_ADMIN.key);
}

export function isChargebackOps(me?: UserRoleObject): boolean {
    return !!me?.roles?.find((item) => item === Roles.CHARGEBACK_OPS.key);
}

// Role Combo helper functions
export function isRoadSyncEmployee(me?: UserRoleObject): boolean {
    return isSuperAdmin(me) || isRSImplementation(me) || isRSEmployee(me) || isRSCheckOps(me);
}

export function isRole_SA_RSI(me?: UserRoleObject): boolean {
    return isSuperAdmin(me) || isRSImplementation(me);
}

export function isRole_CS_EE(me?: UserRoleObject): boolean {
    return isClientSupport(me) || isEmployee(me);
}

// eslint-disable-next-line
export function getLoginRedirectPath(company: Company, me: User): string {
    if (isSuperAdmin(me) || isRSEmployee(me)) {
        return CheckPaths.checksListUrl();
    }

    if (isCompanyTypeTowAndRepare(company.type)) {
        if (isWorkOrderEnabled(company)) {
            return WorkOrderPaths.workOrdersUrl();
        }
    }

    if (isCompanyAdmin(me)) {
        if (isInvoiceDisabled(company)) {
            if (isWorkOrderEnabled(company)) {
                return WorkOrderPaths.workOrdersUrl();
            }
            return AdminPaths.activityListUrl();
        }
    }

    if (isAccountant(me) && isDashboardEnabled(company)) {
        return DashboardPaths.rootUrl();
    }

    if (isEmployee(me) || isClientSupport(me)) {
        if (isInvoiceDisabled(company)) {
            if (isWorkOrderEnabled(company)) {
                return WorkOrderPaths.workOrdersUrl();
            }
        }
    }

    return InvoicePaths.listUrl();
}

export function userCanBankAccount(company: Company, me: User): boolean {
    if (isSecuredBankingEnabled(company)) {
        return isSuperAdmin(me) || isRSImplementation(me) || isFinancialAdmin(me);
    } else {
        return isSuperAdmin(me) || isRSImplementation(me) || isCompanyAdmin(me) || isAccountant(me);
    }
}
