import { AuthEndpoints } from "./endpoints";
import { normalFetch, parseResponse } from "./utils";
import { setTokenRefreshTimer, storeTokens } from "../app/sso";
import { User } from "../../types/User";
import * as HTTP from "./http";
import * as storage from "../app/storage";
import { PayerEntity } from "../../types/PayerEntity";
import { TokenRefreshResponse } from "../../types/Auth";

export async function login(username: string, password: string, code?: string): Promise<User> {
    const r = await normalFetch(AuthEndpoints.LOGIN, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            username: username.trim(),
            password: password.trim(),
            code,
        }),
    });
    storage.setItem("authToken", r.headers.get("Authorization") || "");
    return parseResponse(r);
}

export async function logout(): Promise<void> {
    try {
        await HTTP.postJSON(AuthEndpoints.LOGOUT);

        try {
            storage.clear();
        } catch (_) {}

        // clear localStorage
        try {
            localStorage.clear();
        } catch (_) {}

        // clear sessionStorage
        try {
            sessionStorage.clear();
        } catch (e) {
            console.error("Error logging out", e);
        }

        // clear local, non httpsOnly cookies
        try {
            const cookies: string = document.cookie;

            for (let cookie of cookies.split(";")) {
                cookie = cookie.trim();
                const index: number = cookie.indexOf("=");
                const name: string = index > -1 ? cookie.substring(0, index) : cookie;
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            }
        } catch (_) {}

        // unregister all service workers
        try {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
                registration.unregister();
            }
        } catch (_) {}
    } catch (e) {
        console.error("Error logging out", e);
    }
}

export function getPlaidLinkToken(walletApiUrl: string): Promise<{ data: { linkToken: string } }> {
    return HTTP.request<{ data: { linkToken: string } }>(
        HTTP.Method?.GET,
        walletApiUrl + AuthEndpoints.PLAID_LINK_TOKEN,
        null,
        true,
        undefined,
        "omit"
    );
}

export function authCheck(): Promise<User> {
    return HTTP.get(AuthEndpoints.ME);
}

export function forgotPassword(username: string): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.FORGOT, { username });
}

export function refreshTokens(username: string): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.FORGOT, { username });
}

export function resetPassword(password: string, code: string): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.RESET, { password, code });
}

export function changePassword(newPassword: string, oldPassword: string): Promise<User> {
    return HTTP.postJSON(AuthEndpoints.CHANGE, { newPassword, oldPassword });
}

export async function impersonateLogin(userId: string): Promise<TokenRefreshResponse> {
    const token_response = await HTTP.postJSON<TokenRefreshResponse>(AuthEndpoints.IMPERSONATE_LOGIN, { userId });
    const tokens = storeTokens(token_response.access_token, token_response.refresh_token);
    setTokenRefreshTimer(tokens);
    return token_response;
}

export async function impersonateReset(): Promise<TokenRefreshResponse> {
    const token_response = await HTTP.postJSON<TokenRefreshResponse>(AuthEndpoints.IMPERSONATE_RESET);
    const tokens = storeTokens(token_response.access_token, token_response.refresh_token);
    setTokenRefreshTimer(tokens);
    return token_response;
}

// eslint-disable-next-line max-params
export function setPassword(
    newPassword: string,
    sendNotificationEmail: string,
    generateRandomPassword: string,
    firstPasswordReset: string,
    userId: string
): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.SET_PASSWORD, {
        newPassword,
        sendNotificationEmail,
        generateRandomPassword,
        firstPasswordReset,
        userId,
    });
}

// eslint-disable-next-line max-params
export function signup(firstName: string, lastName: string, phone: string, interests: any): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.SIGNUP, {
        firstName,
        lastName,
        phone,
        interests,
    });
}

export const publicSignup = (r: PayerEntity): Promise<User> => HTTP.postJSON(AuthEndpoints.PUBLICSIGNUP, r);

export async function loginByToken(token: string): Promise<User> {
    const r = await normalFetch(AuthEndpoints.LOGIN_TOKEN(token), {
        method: "POST",
        credentials: "include",
    });
    storage.setItem("authToken", r.headers.get("Authorization") || "");
    return parseResponse(r);
}

export async function loginBySSOToken(code: string): Promise<User> {
    const result = await normalFetch(AuthEndpoints.LOGIN_SSO_CODE(code), {
        method: "GET",
        credentials: "include",
    });
    const typed = (await parseResponse(result)) as any;
    const tokens = storeTokens(typed.accessToken, typed.refreshToken);
    setTokenRefreshTimer(tokens);
    return typed;
}
