import { TokenRefreshResponse } from "../../types/Auth";
import { AuthEndpoints } from "../api/endpoints/AuthEndpoints";
import { throttleAsync } from "../api/utils";
import * as storage from "../app/storage";
import { AuthPaths } from "./paths/AuthPaths";
const ACCESS_TOKEN_BUFFER = 5 * 60 * 1000; // 5 minutes
export const DEFAULT_ACCESS_TOKEN_EXPIRATION = 60 * 60 * 1000; // 55 minutes

export type StoredTokens = {
    accessToken: string | null;
    refreshToken: string | null;
    accessTokenExpires: Date | null;
}

export function isRefreshTokenValid(): "valid" | "invalid" | "none" {
    const { accessTokenExpires, refreshToken } = getStoredTokens();

    // This can be used to force a refresh token to be invalid for dev and testing purposes
    if (!!localStorage.getItem("dev-trip-refresh")) {
        // This value is used to prevent infinite loops of refreshing the access token in dev testing
        localStorage.removeItem("dev-trip-refresh");
        return "invalid";
    }
    if (!refreshToken || !accessTokenExpires) {
        return "none";
    }
    if (new Date().getTime() >= accessTokenExpires.getTime()) {
        return "invalid";
    }
    return "valid";
}

export const refreshAccessToken = throttleAsync(async function refreshAccessToken(refreshToken: string): Promise<TokenRefreshResponse> {

    if (window.location.pathname === "/sso-callback") {
        console.warn("Already at /sso-callback, not refreshing access token");
        return Promise.reject(new Error("Already at /sso-callback"));
    }

    if (!refreshToken) {
        console.error("No refresh token provided, redirecting to login");
        window.location.href = AuthPaths.ssoLink(true);
        return Promise.reject(new Error("No refresh token provided"));
    }

    if (!navigator.onLine) {
        console.warn("Device is offline, unable to refresh access token");
        return Promise.reject(new Error("Device is offline"));
    }

    try {
        const res = await fetch(AuthEndpoints.REFRESH_ACCESS_TOKEN, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ refreshToken, username: "None" }), // username: None is for backwards compatibility
        });
    
        if (!res.ok) {
            window.location.href = AuthPaths.ssoLink(true);
            return Promise.reject(new Error("Failed to refresh access token, redirecting to login"));
        }
    
        const response = await res.json() as TokenRefreshResponse;
    
        const tokens = storeTokens(response.access_token, response.refresh_token, response.expires_in);
        setTokenRefreshTimer(tokens);
        return response;
    } catch (err) {
        console.error("Error refreshing access token", err);
        window.location.href = AuthPaths.ssoLink(true);
        return Promise.reject(err);
    }
}, 5000);

export function storeTokens(accessToken: string, refreshToken: string, expiresIn?: number): StoredTokens {
    if (!accessToken || !refreshToken) {
        console.error("Invalid tokens", { accessToken: !!accessToken, refreshToken: !!refreshToken });
    }

    if (isNaN(expiresIn as number)) {
        console.error("Invalid expiresIn, using default", expiresIn);
        expiresIn = DEFAULT_ACCESS_TOKEN_EXPIRATION;
    }

    // Convert secs to ms
    const expiration = expiresIn ? expiresIn * 1000 : DEFAULT_ACCESS_TOKEN_EXPIRATION;
    const expirationTime = new Date(new Date().getTime() + (expiration - ACCESS_TOKEN_BUFFER));
    storage.setItem("accessToken", accessToken ?? null);
    storage.setItem("refreshToken", refreshToken ?? null);
    storage.setItem("accessTokenExpires", expirationTime.getTime());

    return {
        accessToken,
        refreshToken,
        accessTokenExpires: expirationTime,
    };
}

export const setTokenRefreshTimer = (() => {
    let timer: NodeJS.Timeout | null = null;
    return function setTokenRefreshTimer(tokens: StoredTokens, refresher = refreshAccessToken): void {
        console.log("Setting token refresh timer", tokens);

        // Only one timer at a time
        if (timer) { clearTimeout(timer); }

        if (tokens.accessTokenExpires && tokens.refreshToken) {
            const timeToRefresh = tokens.accessTokenExpires.getTime() - new Date().getTime();
            if (timeToRefresh > 0) {
                timer = setTimeout(async () => await refresher(tokens.refreshToken as string), timeToRefresh);
            }
        }
    }
})();

export function getStoredTokens(): { accessToken: string | null; refreshToken: string | null; accessTokenExpires: Date | null } {
    let accessTokenExpires: Date | null = null;
    const rawTokenDateString = storage.getItem<string | null>("accessTokenExpires");

    if (rawTokenDateString && rawTokenDateString !== "null" && rawTokenDateString !== "undefined") {
        accessTokenExpires = new Date(rawTokenDateString);

        if (isNaN(accessTokenExpires.getTime())) {
            console.error("Invalid accessTokenExpires date", rawTokenDateString);
            accessTokenExpires = null;
        }
    }

    let accessToken: string | null = storage.getItem("accessToken");
    if (accessToken === "null" || accessToken === "undefined") {
        accessToken = null;
    }

    let refreshToken: string | null = storage.getItem("refreshToken");
    if (refreshToken === "null" || refreshToken === "undefined") {
        refreshToken = null;
    }

    return { accessToken, refreshToken, accessTokenExpires };
}