import { createSelector } from "reselect";
import { Environment, GlobalState } from "../types";

export const getAppSettings = (state: GlobalState) => state.appSettings;

export const getAppSettingsLoaded = createSelector([getAppSettings], (appSettings) => {
    return !!appSettings?.settings || !appSettings?.loading;
});

export const getServerEnvironment = createSelector([getAppSettings], (appSettings) => {
    return appSettings?.settings?.config?.serverEnvironment;
});

export const getIsInDevQA = createSelector([getServerEnvironment], (serverEnv) => {
    return serverEnv === Environment.Local || serverEnv === Environment.QA;
});

